/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ./images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const imagePath = (name) => images(name, true)
import 'bootstrap/dist/css/bootstrap.min.css'
import 'stylesheets/application.css'
import './application.css'
import './features/auth'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { setupStore } from './store'
import App from './App'
require.context('images', true)

const container = document.getElementById('root')
if (container) {
  const root = createRoot(container)
  const initialState = JSON.parse(container.getAttribute('data'))

  document.addEventListener('DOMContentLoaded', () => {
    root.render(
      <React.StrictMode>
        <Provider store={setupStore(initialState)}>
          <App />
        </Provider>
      </React.StrictMode>
    )
  })
}
