import { configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import _ from 'lodash'
import toCamel from 'lodash-humps'
import { homeSlice } from '../features/home'
import { commonSlice } from '../features/common'
import { authSlice } from '../features/auth'
import { profileSlice } from '../features/profile'
import { settingSlice } from '../features/setting'
import { adminSlice } from '../features/admin'
import { examOrCourseSlice } from '../features/examOrCourse'

const slices = [homeSlice, commonSlice, authSlice, profileSlice, settingSlice, adminSlice, examOrCourseSlice]

const reducer = _.reduce(
  slices,
  (result, slice) => _.merge(result, { [slice.name]: slice.reducer }),
  {}
)

export const setupStore = initialState => configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  preloadedState: _.reduce(
    slices,
    (result, slice) => _.merge(result, { [slice.name]: slice.getInitialState() }),
    toCamel(initialState)
  )
})
